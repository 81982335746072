import { showLoading, hideLoading } from "react-redux-loading"
import { getCheckoutObject } from "../utils/api"



export const GET_CHECKOUT_TOKEN = 'GET_CHECKOUT_TOKEN'

export const checkoutToken = (checkout) => ({
  type: GET_CHECKOUT_TOKEN,
  checkout
})

export function handleGetCheckoutToken(cartId) {
  return async (dispatch) => {
    dispatch(showLoading())
    const { checkout } = await getCheckoutObject(cartId)
    dispatch(checkoutToken(checkout))
    dispatch(hideLoading())
  }
}