import React, { Component, Fragment } from 'react'
import '../style/App.scss'

import { connect } from 'react-redux'
// import { handleInitialData } from '../actions'

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import LoadingBar from 'react-redux-loading'
import NavBar from './NavBar'
import Feedback from './Feedback'
import Home from './Home'
// import Cart from './Cart'
import Instructions from './Instructions'
// import Shop from './Shop'
// import Story from './Story'
import Events from './Events'
import Footer from './Footer'
import Checkout from './Checkout'
import Confirmation from './Confirmation'
import Notification from './Notification'
import Terms from './Terms'
import Privacy from './Privacy'

class App extends Component {

  // componentDidMount() {
  //   this.props.dispatch(handleInitialData())
  // }

  render () {
    const cartItems = this.props.cart.cart === undefined ? 0 : this.props.cart.cart.total_items 
    return (
      window.location.pathname === '/terms' || window.location.pathname === '/privacy' ?
      <Router>
        <Routes>
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
        </Routes>
      </Router>
      :
      <Router>
        <Fragment>
          <NavBar cartItems={cartItems}/>
          <LoadingBar style={{ backgroundColor: '#0c14ff',
                              height: '7px', 
                              position: 'absolute', 
                              top: '75px',
                              zIndex:'100' }} />
          
          <Feedback blink={this.props.feedback}/>
          <Notification feedback={this.props.feedback} />
          <div className="spacer"></div>
          <Routes className="mt-5">
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            {/* <Route path='/cart' 
              element={<Cart cart={this.props.cart.cart} 
                      items={this.props.items}
                      categories={this.props.categories}
                      />} /> */}
            <Route path='/instructions' element={<Instructions />} />
            {/* <Route path='/shop' element={<Shop />} /> */}
            {/* <Route path='/story' element={<Story />} /> */}
            <Route path='/events' element={<Events />} />
            <Route path='/checkout/:id' element={<Checkout />} />
            <Route path='/confirmation' element={<Confirmation />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
          < Footer />
        </Fragment>
      </Router>
    )
  }
}

function mapStateToProps({ data, feedback, cart }) {
  return {
    items: data.items,
    categories: data.categories,
    cart, 
    feedback,
  }
}

export default connect(mapStateToProps)(App)
