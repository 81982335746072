import {
  GET_CHECKOUT_TOKEN
} from '../actions/checkout'

export default function checkout(state = {}, action) {
  switch (action.type) {
    case GET_CHECKOUT_TOKEN:
      return {
        ...state,
        checkout: { ...action.checkout }
      }
   

    default:
      return state
  }
}