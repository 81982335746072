import React, {useEffect, useState} from 'react'
import ReactConfetti from 'react-confetti'

const Confetti = ()=> {
  const [windowDimension, setDimension] = useState(
    {
      width: window.innerWidth, 
      height: window.innerHeight
    }
  )

  const [count, setCount] = useState(1000)

  setTimeout(() => {
    setCount(0)
  }, 1500)

  const detectSize = () => {
    setDimension(
      {
        width: window.innerWidth,
        height: window.innerHeight
      })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimension])

  return (
    <>
      <ReactConfetti 
        width={windowDimension.width}
        height={windowDimension.height}
        tweenDuration={200}
        initialVelocityX={1}
        numberOfPieces={count}
      />
    </>
  )
}

export default Confetti