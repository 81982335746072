import { combineReducers } from "redux"
import data from './data'
import cart from './cart'
import modal from "./modal"
import feedback from "./feedback"
import checkout from "./checkout"

import { loadingBarReducer } from "react-redux-loading"

export default combineReducers({
  data,
  cart,
  checkout,
  modal,
  feedback,
  loadingBar: loadingBarReducer
})