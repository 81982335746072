import React from 'react'
import { Image } from 'react-bootstrap'
import { motion } from "framer-motion";

import '../style/App.scss'

import oven_icon from '../style/images/icons/oven.png'
import pizza_icon from '../style/images/icons/pizza.png'
import timer_icon from '../style/images/icons/timer.png'
import plate_icon from '../style/images/icons/plate.png'
import fryer_icon from '../style/images/icons/fryer.png'
import air_fryer_icon from '../style/images/icons/air_fryer.png'

function FadeInWhenVisible({ children, delay=0.2 }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={
        {
         duration: 0.4, 
         delay, 
         bounce: 0.4, 
         type: 'spring'}
        }
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

function Instructions() {

  return (
    <div className='background-instructions'>
      <div className='container pt-5 pb-5'>
        <h1 className='mt-4 instruction-header'>PIZZA COOKING INSTRUCTIONS</h1>
        <FadeInWhenVisible>
          <h2 className='instruction-header2'>STEP 1</h2>
          <div className='row pt-2 pb-3'>
            <div className='col-4 p-2 icon-container'>
              <Image className="instruction-icon" src={oven_icon} alt="oven icon" />
            </div>
            <div className='col-8 instruction-description'>
              Preheat the oven at 425°F / 220°C
            </div>
          </div>
        </FadeInWhenVisible>
        
        <FadeInWhenVisible delay={0.6}>
          <h2 className='instruction-header2'>STEP 2</h2>
          <div className='row pt-2 pb-3'>
            <div className='col-4 icon-container'>
              <Image className="instruction-icon" src={pizza_icon} alt="pizza icon" />
            </div>
            <div className='col-8 instruction-description'>
              Remove the pizza from packaging and place
              directly on the oven rack. Feel free to add your extra
              toppings before baking.
            </div>
          </div>
        </FadeInWhenVisible>

        <FadeInWhenVisible>
          <h2 className='instruction-header2'>STEP 3</h2>
          <div className='row pt-2 pb-3'>
            <div className='col-4 icon-container'>
              <Image className="instruction-icon" src={timer_icon} alt="timer icon" />
            </div>
            <div className='col-8 instruction-description'>
              Bake for 9/10 minutes to obtain the original
              neapolitan pizza. Bake up to 12 minutes for crispier result.
            </div>
          </div>
        </FadeInWhenVisible>

        <FadeInWhenVisible>
          <h2 className='instruction-header2'>STEP 4</h2>
          <div className='row pt-2 pb-3'>
            <div className='col-4 icon-container'>
              <Image className="instruction-icon" src={plate_icon} alt="plate icon" />
            </div>
            <div className='col-8 instruction-description'>
              Remove the pizza from the oven and make sure
              you enjoy it until the last bite.
            </div>
          </div>
        </FadeInWhenVisible>
      </div>

      <div className='container pb-5 color-primary'>
      <FadeInWhenVisible>
        <h1 className='mt-4 mb-4 instruction-header'>ARANCINI COOKING INSTRUCTIONS</h1>
        <div className='row text-center'>
          <div className='col-4 instruction-table-head'>Method</div>
          <div className='col-2 instruction-table-head'>Defrost</div>
          <div className='col-3 instruction-table-head'>Temp.</div>
          <div className='col-3 instruction-table-head'>Time</div>
        </div>
        <hr className="sep-2" />
        </FadeInWhenVisible>

        <FadeInWhenVisible>
        <div className='row text-center fs-3'>
          <div className='col-4'>
            <div className="text-center">Deep fry</div>
            <div className="text-center">
              <Image className='instruction-icon' src={fryer_icon} alt="Deep Fryer" />
            </div>
          </div>
          <div className='col-2 m-auto checkmark'>✓</div>
          <div className='col-3 m-auto'>330°F</div>
          <div className='col-3 m-auto'>5 mins</div>
        </div>
        <hr className="sep-2" />
        </FadeInWhenVisible>
      
      <FadeInWhenVisible>
        <div className='row text-center fs-3'>
          <div className='col-4'>
            <div>Oven</div>
            <div>
              <Image className='instruction-icon' src={oven_icon} alt="Oven" />
            </div>
          </div>
          <div className='col-2 m-auto'>❌</div>
          <div className='col-3 m-auto'>400°F</div>
          <div className='col-3 m-auto'>18 mins</div>
        </div>

        <hr className="sep-2" />
        </FadeInWhenVisible>

        <FadeInWhenVisible>
        <div className='row text-center fs-3'>
          <div className='col-4'>
            <div>Air Fryer</div>
            <div>
              <Image className='instruction-icon' src={air_fryer_icon} alt="Air Fryer" />
            </div>
          </div>
          <div className='col-2 m-auto'>❌</div>
          <div className='col-3 m-auto'>450°F</div>
          <div className='col-3 m-auto'>25 mins</div>
        </div>
        </FadeInWhenVisible>
      </div>
    </div>

  )
}

export default Instructions