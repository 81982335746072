export const SHOW_FEEDBACK = 'SHOW_FEEDBACK'
export const HIDE_FEEDBACK = 'HIDE_FEEDBACK'
export const SHOW_ERROR = 'SHOW_ERROR'
export const HIDE_ERROR = 'HIDE_ERROR'

export const showFeedback = () => ({
  type: SHOW_FEEDBACK,
  show: true
})

export const hideFeedback = () => ({
  type: HIDE_FEEDBACK,
  show: false
})

export const showMessage = (message, error=false) => ({
  type: SHOW_ERROR,
  message,
  error
})

export const hideMessage = (error=false) => ({
  type: HIDE_ERROR,
  error,
  message: undefined
}) 

export function handleBlinkFeedback() {
  return (dispatch) => {
    dispatch(showFeedback())
    setTimeout(() => dispatch(hideFeedback()), 1000)
  }
}

/**
 * 
 * @param {String} message Error message to show
 * @returns 
 */
export function toggleError(message) {
  return (dispatch) => {
    dispatch(showMessage(message, true))
    setTimeout(() => dispatch(hideMessage(false)), 4000)
  }
}

export function toggleSuccess(message) {
  return (dispatch) => {
    dispatch(showMessage(message, false))
    setTimeout(() => dispatch(hideMessage(false)), 4000)
  }
}