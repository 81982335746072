import React, { useState, useEffect, useRef } from 'react'
import '../style/App.scss'
import { Image } from 'react-bootstrap'
import logo_font from '../style/images/logo_font.png'
import frozen_icon from '../style/images/icons/frozen_icon_no_bg.png'
import handcrafted_icon from '../style/images/icons/handcrafted_no_bg.png'
import natural_icon from '../style/images/icons/natural_icon_no_bg.png'
import quality_control from '../style/images/quality_control.png'
import images from '../utils/images'

import { useAnimation, useInView } from 'framer-motion'
import {
  motion,
  useScroll,
  useTransform
} from "framer-motion";



/**
 * 
 * @param {Integer} value Y value
 * @param {Integer} distance Y starting distance
 * @returns 
 */
function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

/**
 * 
 * @param {Object} title Title to display 
 * @returns 
 */
function CreateIcon({ title }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, 300);
  return (React.createElement("div", { ref: ref, className: "title-container" },
    React.createElement(motion.h2, { style: { y }, className: "icon-title" }, title)));
}

/**
 * 
 * @param {Integer} [y] OnScreen y value, default 0
 * @param {Integer} [x] OnScreen x value, default 0
 * @param {Integer} [rotation] OnScreen rotation value (Degrees), default 0
 * @param {Integer} [delay] Animation delay, default 0
 * @returns Object
 */
const animationSettings = (y = 0, x = 0, rotation = 0, delay = 0) => {
  return {
    offscreen: {
      y: '100vh',
      x: '-100vw'
    },
    onscreen: {
      y: y,
      x: x,
      rotate: rotation,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
        delay: delay
      }
    }
  }
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const useSlideIn = (ref, animation, leftOrRight = 'left') => {
  const inView = useInView(ref)
  const direction = leftOrRight.toLowerCase() === 'left' ? '-100vw' : '100vw'

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: 'spring',
          duration: 1,
          bounce: 0.5,
          delay: 0.3
        }
      })
    }
    if (!inView) {
      animation.start({ x: direction })
    }
  }, [inView])
}

export default function Home(props) {

  const { height, width } = useWindowDimensions()
  const ref1 = useRef(null)
  const animation = useAnimation()
  const [slideshowWidth, setSlideShowWidth] = useState()
  const slideshow = useRef()

  useEffect(() => {
    setSlideShowWidth(slideshow.current.scrollWidth - slideshow.current.offsetWidth)
  }, [])


  useSlideIn(ref1, animation)

  return (
    <div >
      <motion.div className='img-background' initial={{ opacity: 0 }} transition={{ ease: "easeOut", duration: 2 }}
        animate={{ opacity: 1 }}>
        {/* <p className='text-paragraph fst-italic lineUp'>
          Eating is an experience, eating good food elevates the experience to a magical
          connection between palate and emotions. The flavors in your mouth should create joy,
          so let your mind fly away to pristine places and disconnect for a moment from the
          frenetic life while enjoying our authentic Italian food product.
        </p> */}
        <h1 className='lineUp main-title'>
          Simplicity is the ultimate sophistication...
        </h1>
        <small className='lineUp small'>Leonardo Da Vinci</small>
      </motion.div>
      <div className='logo-header'>
        <Image src={logo_font} fluid="true" className='mt-3' alt='logo font' />
      </div>

      {width > 768 ?

        /* COTAINER */
        <motion.div className='card-container'
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          {/* BACKGROUND */}
          <div className='background-splash'></div>
          {/* CARD 1 */}
          <motion.div
            variants={animationSettings(0, -150, -12)}
            className='custom_card'>
            <Image src={frozen_icon} alt="frozen item icon" className='icons' />
          </motion.div>

          {/* CARD 2 */}
          <motion.div
            variants={animationSettings(-20, 0, -5, 0.7)}
            className='custom_card'>
            <Image src={handcrafted_icon} alt="handcrafter icon" className='icons' />
          </motion.div>

          {/* CARD 3 */}
          <motion.div
            variants={animationSettings(-20, 150, 4, 1.4)}
            className='custom_card'>
            <Image src={natural_icon} alt="natural icon" className='icons' />
          </motion.div>
        </motion.div> //End of container
        :

        /* SETTINGS FOR FOR SMALL SCREEN */
        (
          <div className='container'>

            <motion.div className='card-container'
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              {/* BACKGROUND */}
              <div className='background-splash'></div>
              {/* CARD 1 */}
              <motion.div
                variants={animationSettings(0, 0, -12)}
                className='custom_card'>
                <Image src={frozen_icon} alt="frozen item icon" className='icons' />
              </motion.div>
            </motion.div>
            {React.createElement(CreateIcon, { icon: frozen_icon, title: "Blast Frozen" })}

            <motion.div className='card-container'
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              {/* BACKGROUND */}
              <div className='background-splash'></div>
              {/* CARD 2 */}
              <motion.div
                variants={animationSettings(0, 0, -12)}
                className='custom_card'>
                <Image src={handcrafted_icon} alt="handcrafted icon" className='icons' />
              </motion.div>
            </motion.div>
            {React.createElement(CreateIcon, { icon: handcrafted_icon, title: "Hand made" })}

            <motion.div className='card-container'
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              {/* BACKGROUND */}
              <div className='background-splash'></div>
              {/* CARD 3 */}
              <motion.div
                variants={animationSettings(0, 0, -12)}
                className='custom_card'>
                <Image src={natural_icon} alt="natural icon" className='icons' />
              </motion.div>
            </motion.div>
            {React.createElement(CreateIcon, { icon: natural_icon, title: "100% Natural" })}

          </div>
        )
      }

      <div className='title margin-tb-4' ref={ref1}>
        <motion.h2
          animate={animation}
        >
          While everyone in the world prepares over processed and imitations of food,
          we bring you the difference. Original recipes.
        </motion.h2>
      </div>
      {/* <a href="https://order.online/business/pizzacini-corp-11854875"  className='shop-link' target='blank'>
        ORDER NOW
      </a> */}

      <div className='img-container container'>
        <h2 animate={animation} className="img-title">
          EVERY PRODUCT IS QUALITY INSPECTED
        </h2>
        <Image className="w-100" src={quality_control} alt="Quality control" />
      </div>

      <h2 className='title text-center'>
        UNLIMITED POSSIBILITIES
      </h2>
      <div className='img-paragraph text-justify'>
        <div
          className="paragraph-text"
        >
          Whether you find that you are short on time, are without the right equipment
          in your restaurant, or you simply want an amazing, original pizza at home,
          we are here to serve you. We handcraft our pizza and blast chill them
          afterwards in order to maintain the highest standard of quality.
        </div>
        <h1 className='paragraph-title'>
          If we do all the work, what is left for you?
        </h1>
        <div className='paragraph-text'>
          It's simple, just take out your frozen pizza from the freezer,
          warm it up in the oven, show off and enjoy an incredibly authentic tasting pizza,
          as if it had come directly from a neapolitan wood-burning oven.
        </div>
      </div>
      {React.createElement(CreateIcon, { title: "THINK ORIGINAL" })}

      <motion.div className='slideshow' ref={slideshow}>
        <motion.div drag="x"
          dragConstraints={{ right: 0, left: -slideshowWidth }}
          className='inner-slideshow'>
          {images.map((image, index) => {
            return (
              <motion.div className='item' key={index}>
                <Image src={image} alt={`image ${index}`} 
                />
              </motion.div>
            )
          })}
        </motion.div>
      </motion.div>

      <div className='row container mt-5 m-auto'>
        <div className='col-md-4 col-12 mb-5'>
          <h1 className='paragraph-title m-0'>FREEDOM</h1>
          <p className='paragraph-text fs-4 m-0 p-1 text-justify'>
            We empower you to became the next entrepeneaur: we got you covered. 
            We give you infinite possibility, whether you have a restaurant or thinking 
            to open your next business, we set you for success. 
            We follow a simple rule, stay genuine. 
          </p>
        </div>
        <div className='col-md-4 col-12 mb-5'>
          <h1 className='paragraph-title m-0'>UNRIVALLED TASTE</h1>
          <p className='paragraph-text fs-4 m-0 p-1 text-justify'>Perfection means caring about the craftmanship even of the unseen. 
            We monitor every single process of our production, to offer an 
            always authentic and perfect product. We are eager on finding always 
            the best ingredients, the ones that mother nature offers to us without any
            human alteration.
          </p>
        </div>
        <div className='col-md-4 col-12 mb-5'>
          <h1 className='paragraph-title m-0'>CREATIVITY</h1>
          <p className='paragraph-text fs-4 m-0 p-1 text-justify'>For the professionals, looking to offer unmatched quality products, we lift all
            the chefs labour from you, thanks to our team of master pizzaioli and chefs, 
            so you can focus on selling and serving. 
            For the lunch, dinner or simply a snack at home, make an impression with your guests. 
            We got the equipment, you unleash the creativity.
          </p>
        </div>
      </div>

    </div>
  )
}