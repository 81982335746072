import React from "react"
import { connect } from "react-redux"
import { hideMessage } from "../actions/feedback"

function Notification (props) {
  const classes = props.feedback.error ? 'alert-danger' : 'alert-success'
  if (props.feedback.message) {
    return (
      <div className={`notification alert align-items-center fade show ${classes}`}>
        <div className="d-flex">
          <div className="toast-body">
            {props.feedback.message}
          </div>
          <button 
          type="button" className="btn-close me-2 m-auto"  
            onClick={() => props.dispatch(hideMessage())}
            aria-label="Close"></button>
        </div>
      </div>
    )
  }
  return (
    <div></div>
  )
}

export default connect()(Notification)