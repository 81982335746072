import React from 'react'
import '../style/App.scss'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// import { Basket } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'


export default function NavBar(props) {

  const dismissNavbar = () => {
    // const mainNav = document.querySelector('nav')
    const mainNav = document.querySelector('#basic-navbar-nav')
    const toggBtn = document.querySelector('#toggler')

    window.addEventListener('click', function (evt) {
      if (evt.target.matches('.nav-link') || evt.target.matches('.navbar-nav') || evt.target.matches(".navbar-collapse")
        || evt.target.matches("#toggler") || evt.target.matches('.navbar-toggler-icon') ||
        !mainNav
      ) {
        return
      }

      mainNav.classList.remove('show')
      toggBtn.classList.add('collapsed')
    })
  }
  dismissNavbar()

  return (
    <div>
      <Navbar bg="custom" expand="md" variant="dark" fixed="top">
        <NavLink to="/home" exact="true" className="logo me-auto order-1 navbar-brand" href="#shop">
        </NavLink>

        {/* CART DISABLED WHEN EXTERNAL ORDERING PROVIDER IS USED */}
        {/* <Nav className="me-3 order-md-3 order-2">
            <NavLink to="cart" href="#cart" className="nav-link">
              Cart
              <Basket size={24} className="ms-2"/>
              <div className="cart-number">
                {props.cartItems === 0 ? "" : props.cartItems}
              </div>
            </NavLink>
          </Nav> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="order-3" id='toggler' />
        <Navbar.Collapse id="basic-navbar-nav" className="ms-3 order-4 order-md-2">
          <Nav className="me-auto">
            <NavLink to="/home" exact="true" className="nav-link">
              Home
            </NavLink>
            <NavLink to="/events" className="nav-link">
              Events
            </NavLink>
            <NavLink to="/instructions" className="nav-link">
              Instructions
            </NavLink>
            {/* <NavLink to="/story" className="nav-link">
              Our Story
            </NavLink> */}
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    </div>
  )
}