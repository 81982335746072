import { handleBlinkFeedback } from "./feedback"
import { addItemToCart, 
         deleteCartItem, 
         updateCart, 
         emptyCart,
         refreshCart } from "../utils/api"
import { showLoading, hideLoading } from "react-redux-loading"


export const INITIALIZE_CART = 'INITIALIZE_CART'
export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY'
export const EMPTY_CART = 'EMPTY_CART'
export const REFRESH_CART = 'REFRESH_CART'


/**
 * 
 * @param {Object} cart cart Object from api containing at least cart id
 * @returns {Object} payload
 */
 export const initializeCart = (cart) => ({
  type: INITIALIZE_CART,
  cart
})

/**
 * 
 * @param {Object} cart updated cart Object from API
 * @returns {Object} payload
 */
const addItem = (cart) => ({
  type: ADD_CART_ITEM,
  cart
})

/**
 * 
 * @param {string} id 
 * @returns {Object} payload
 */
const removeItem = cart => ({
  type: REMOVE_ITEM,
  cart
})

/**
 * 
 * @param {string} id 
 * @param {integer} quantity new quantity 
 * @returns {Object} payload
 */
const updateItemQuantity = (cart) => ({
  type: UPDATE_ITEM_QUANTITY,
  cart
})

const emptyCartAction = (cart) => ({
  type: EMPTY_CART,
  cart
})

const refreshCartAction = (cart) => ({
  type: REFRESH_CART,
  cart
})

/**
 * 
 * @param {Object} commerce Object instance of commercejs
 * @param {String} item.id
 * @param {Integer} item.quantity
 * @returns {Object} 
 */
export function handleAddItemToCart(commerce, item) {
  return async (dispatch) => {
    dispatch(handleBlinkFeedback())
    const { cart } = await addItemToCart(commerce, item)
    dispatch(addItem(cart))
  }
}

/**
 * 
 * @param {String} id 
 * @returns {Object}
 */
export function handleRemoveItemFromCart(id) {
  return async (dispatch) => {
    dispatch(showLoading())
    const { cart } = await deleteCartItem(id)
    dispatch(removeItem(cart))
    dispatch(hideLoading())
  }
}

/**
 * 
 * @param {string} id 
 * @param {integer} quantity 
 * @returns {Object}
 */
export function handleUpdateItemQuantity(id, quantity) {
  return async (dispatch) => {
    dispatch(handleBlinkFeedback())
    const { cart } = await updateCart(id, quantity)
    dispatch(updateItemQuantity(cart))
  }
}

export function handleEmptyCart() {
  return async (dispatch) => {
    dispatch(showLoading())
    const { cart } = await emptyCart()
    dispatch(hideLoading())
    dispatch(emptyCartAction(cart))
  }
}

export function handleRefreshCart() {
  return async (dispatch) => {
    const cart = await refreshCart()
    dispatch(refreshCartAction(cart))
  }
}