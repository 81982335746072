import { arrayToKeyedJson } from './helper'
import commerce from '../lib/commerce'

/**
 * 
 * @returns {Object} catetories, items, categoriesIds, cart
 */
export async function getInitialData () {
  try {
    const cat_data = await commerce.categories.list()
    const categories = arrayToKeyedJson('id', cat_data.data)
    const items_data = await commerce.products.list()
    const items = arrayToKeyedJson('id', items_data.data)
    const cart = await commerce.cart.retrieve()

    if (cat_data === undefined) {
      console.log(cat_data);
      throw "Unable to retrieve data"
    }

    return {
      categories,
      items,
      categoriesIds: Object.keys(categories),
      cart,
      error: undefined
    }
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

/**
 * 
 * @param {Object} item Object containing product id and quantity 
 *                      {
 *                        id: "prod_G790Vwdklslk",
 *                        quantity: 5
 *                      }
 * @param {String} item.id
 * @param {Integer} item.quantity
 * @returns Response Object
 */
export async function addItemToCart (item) {
  try {
    const data = await commerce.cart.add(item.id, item.quantity)
    return data
  } catch (e) {
    console.error(e);
    return {
      error: e
    }
  }
}


/**
 * 
 * @param {string} id ID of the item to remove
 * @returns {Object} returns updated cart
 */
export async function deleteCartItem (id) {
  try {
    const cart = await commerce.cart.remove(id)
    return cart
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

/**
 * 
 * @param {String} id ID of the item to be updated
 * @param {Integer} quantity new quantity
 * @returns {Object} returns new cart object
 */
export async function updateCart (id, quantity) {
  try {
    const cart = await commerce.cart.update(id, { quantity })
    return cart
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

export async function emptyCart () {
  try {
    const cart = await commerce.cart.empty()
    return cart
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

/**
 * 
 * @param {string} cartId cartId which you want to generate the checkout token of
 * @returns {Object}
 */
export async function getCheckoutObject(cartId) {
  try {
    const token = await commerce.checkout.generateToken(
      cartId,
      {type: 'cart'}
    )
    return token
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

/**
 * 
 * @param {string} checkoutId checkout id received in the checkout generateToken method
 * @param {Object} data Object containing the user data
 * @returns {Object} order object
 */
export async function handleCaptureOrder(checkoutId, data) {
  try {
    const order = await commerce.checkout.capture(checkoutId, data)
    return order
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

//  STRONG CUSTOMER AUTHENTICATION //
// Return the neccessary billing details from the current checkout if billing address is collected

/**
 * 
 * @param {Object} checkout Checkout object from commerce api
 * @param {Object} orderData Object containing customer information
 * @returns 
 */
const billingDetails = (checkout, orderData) => {
  if (!checkout.conditionals.collects_billing_address) {
    return null;
  }
  console.log(checkout);

  // Format and pass in the billing key values for the verification details
  return {
    givenName: orderData.billing.firstname,
    familyName: orderData.billing.lastname,
    addressLines: [orderData.billing.street],
    email: orderData.customer.email,
    state: orderData.billing.county_state,
    city: orderData.billing.town_city,
    postalCode: orderData.billing.postal_zip_code,
    countryCode: orderData.billing.country,
  }
}

// Create a verifyBuyerDetails function and format out the parameter for the verifyBuyer method
// https://developer.squareup.com/reference/sdks/web/payments/objects/ChargeVerifyBuyerDetails
/**
 * 
 * @param {Object} orderData Object containing customer information
 * @param {Object} checkout Checkout object from commerce api
 * @param {Object} payments Checkout object from square payment sdk
 * @param {string} token Token received from square payment
 * @returns 
 */
const verifyBuyerDetails = async (orderData, checkout, payments, token) => {
  const verificationDetails = {
    amount: checkout.live.total_with_tax.formatted,
    currencyCode: checkout.merchant.currency.code,
    intent: 'CHARGE',
    billingContact: billingDetails(checkout, orderData),
  };
  const verificationResults = await payments.verifyBuyer(
    token,
    verificationDetails
  );
  return verificationResults.token;
}

/**
 * 
 * @param {Object} card Object returned by squarePayments API call
 * @param {string} checkoutId checkout id received in the checkout generateToken method
 * @param {Object} orderData Object containing the user data
 * @returns {Object} order object
 */
export async function handleSquareCaptureOrder (card, checkout, orderData, payments) {

  // Get the payment form token from the Card instance when the order is submitted by calling the tokenize method.
  const paymentMethodResponse = await card.tokenize();

  let token;
  // The response will be either a valid credit card or an error.
  if (paymentMethodResponse.status === 'OK') {
    token = paymentMethodResponse.token
  } else {
    // There was some issue with the information that the customer entered into the payment details form.
    const errorBody = JSON.stringify(paymentMethodResponse.errors);
    throw new Error(errorBody);
  }


  // Call the `verifyBuyerDetails` function and pass in the response token
  const verificationToken = await verifyBuyerDetails(orderData, checkout, payments, token);

  try {
    const order = await commerce.checkout.capture(checkout.id, {
      ...orderData,
      // Include Square payment method token:
      payment: {
        gateway: 'square',
        square: {
          token: token,
          verification_token: verificationToken
        }
      },
    })
    // If we get here, the order has been successfully captured and the order detail is part of the `order` variable
    return order
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
};

export async function refreshCart () {
  try {
    const cart = await commerce.cart.refresh()
    return cart
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

/**
 * 
 * @param {String} checkoutId checkout id received in the checkout generateToken method
 * @returns {Object} live checkout object that includes taxes
 */
 export async function getLiveObject(checkoutId) {
  try {
    const liveObj = await commerce.checkout.getLive(checkoutId)
    return liveObj
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

/**
 * 
 * @param {String} checkoutId checkout id received in the checkout generateToken method 
 * @param {String} shippingOptionId shipping method to use
 * @param {String} country The shipping country of the customer (a valid ISO 3166-1 alpha-2 country code)
 * @param {String} region Must be a valid short code for the region, e.g. CA for California or QB for Quebec. 
 * @returns {Object} live checkout object
 */
export async function getShippingMethod(checkoutId, shippingOptionId, 
                                        country, region) {
  try {
    const liveObj = await commerce.checkout.checkShippingOption(checkoutId, {
      shipping_option_id: shippingOptionId,
      country,
      region
    })
    return liveObj
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
} 

/**
 * Use default parameters in process.env 
 * keys to be used in .env file: REACT_APP_TAX_COUNTRY, REACT_APP_TAX_REGION, REACT_APP_TAX_ZIP
 * @param {String} checkoutId checkout token
 * @param {String} country Must be a valid ISO 3166-1 alpha-2 country code (e.g. GB for United Kingdom)
 * @param {String} region Only required if Canada (CA) or USA (US). Must be a valid short code for the region, e.g. CA for California, or FL for Florida.
 * @param {String} postal_zip_code Only required if merchant has Auto US Sales Tax turned on.
 * @returns {Object} live checkout object
 */
export async function setTaxZone(checkoutId, 
    country=process.env.REACT_APP_TAX_COUNTRY, 
    region=process.env.REACT_APP_TAX_REGION,
    postal_zip_code=process.env.REACT_APP_TAX_ZIP) {
  try {
    const liveObj = await commerce.checkout.setTaxZone(checkoutId, 
        {
          country,
          region,
          postal_zip_code
        }
      )
    return liveObj
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

/**
 * 
 * @param {String} checkoutId checkout id received in the checkout generateToken method
 * @param {String} discountCode code entered by the user
 * @returns {Object} live checkout object that includes taxes
 */
 export async function checkDiscount(checkoutId, discountCode) {
  try {
    const liveObj = await commerce.checkout.checkDiscount(checkoutId, {code: discountCode})
    return liveObj
  } catch (e) {
    console.error(e)
    return {
      error: e
    }
  }
}

// /**
//  *
//  * @param {Object} loadScript Paypal object loaded with the paypal SDK script (@paypal/paypal-js)
//  * @param {String} domElem DOM element to apped the paypal buttons
//  * @param {String} clientId client Id to use the PayPal SDK. Default "test"
//  * @returns 
//  */
// export async function renderPaypal(loadScript, paypalAuth, domElem, clientId="test") {
//   let paypal 
//   try {
//     paypal = await loadScript({ "client-id": clientId });
//   } catch (error) {
//       console.error("failed to load the PayPal JS SDK script", error);
//   }

//   if (paypal) {
//       try {
//           await paypal.Buttons().render({
//             env: 'sandbox', // or 'production'
//             commit: true,
//             payment: function() {
//               return paypalAuth.payment_id
//             },
//             onAuthorize: function(data, actions) {
//               // capture order here and send payment_id and payer_id to Chec
//             },
//             oncCancel: function (data, actions) {
//               // Handler if the user does not authorize the payment
//             }
//           },
//             domElem);
//       } catch (error) {
//           console.error("failed to render the PayPal Buttons", error);
//       }
//   }
// }