import { ADD_CART_ITEM, 
         handleUpdateItemQuantity } from "../actions/cart"

/**
 * 
 * @function checkAndAdd checks if an item is already present
 *                       in the cart before adding it again. 
 *                       If yes, the function will dispatch
 *                       the UPDATE function, and change the 
 *                       quantity of the item already present
 *                       in the cart.
 */
const checkAndAdd = (store) => (next) => (action) => {
  const cart = store.getState().cart
  if (
    action.type === ADD_CART_ITEM &&
    action.id in cart    
    ) {
      const newQuantity = cart[action.id] + action.quantity 
     return store.dispatch(handleUpdateItemQuantity(action.id, newQuantity))
    }
  return next(action)
}

export default checkAndAdd