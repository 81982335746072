/**
 * 
 * @param {string} categoryId UID of the category
 * @param {object} items Object containing all the items
 * 
 * @returns Array of objects
 */
export function getItemsByCategory(categoryId, items) {
  const filteredItems = []
  for (const [key, value] of Object.entries(items)) {
    if (value.categories.find(elem => elem.id === categoryId)) {
      filteredItems.push(value)
    }
  }
  return filteredItems
}

/**
 * 
 * @param {object} cart Object containing key and value, value as integer
 * @returns Total number of items in the cart. Integer or 0 if cart is an empty object
 */
export function getTotItemsInCart(cart) {
  let total = 0
  for (const [key, value] of Object.entries(cart)) {
    total = total + value
  }
  return total
}

/**
 * 
 * @param {Object} cart object containing the items in the cart {key: value}
 * @param {Object} items object containing all the items available
 * @returns Object with the following keys: cartItems and total. If there are
 *          no items in the cart, the object returned will be {cartItems: [], total:0}
 */
export function getCartItems(cart, items) {
  const cartItems = []
  let sum = 0
  if (!cart || !items) return { cartItems: [], total: 0 }
  if (Object.keys(cart).length === 0 || Object.keys(items).length === 0) return { cartItems: [], total: 0 }
  for (const [key, value] of Object.entries(cart)) {
    const obj = items[key]
    obj.quantity = value
    cartItems.push(obj)
    sum = sum + (items[key].price.raw * value)
  }
  return {
    cartItems,
    total: sum
  }
}

/**
 * 
 * @param {string} key is the key in the array object that will be used as key for the output object
 * @param {array} array array to convert into keyed object
 * @returns Keyed Object example: {"kldjsaflka": {id: "kldjsaflka", name: "something"}}
 */
export function arrayToKeyedJson(key, array) {
  const obj = {}
  for (const value of array) {
    obj[value[key]] = value
  }
  return obj
}

/**
 * This helper function is to be used to capture the order, it formats the data
 * in the way the api expects it.
 * @param {object} lineItems lineItems from comemrce cart
 * @returns 
 */
export function sanitizedLineItems(lineItems) {
  return lineItems.reduce((data, lineItem) => {
    const item = data;
    let variantData = null;
    if (lineItem.selected_options.length) {
      variantData = {
        [lineItem.selected_options[0].group_id]: lineItem.selected_options[0].option_id,
      };
    }
    item[lineItem.id] = {
      quantity: lineItem.quantity,
      variants: variantData,
    };
    return item;
  }, {});
}

/**
 * 
 * @param {String} [type="localStorage"] Type of storage to test [localStorage, SessionStorage]. Default is sessionStorage
 * @returns 
 */
export function storageAvailable(type = 'localStorage') {
  let storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
}

export function convertUnixToDatetime(unix_timestamp) {

// Create a new JavaScript Date object based on the timestamp
// multiplied by 1000 so that the argument is in milliseconds, not seconds.
const date = new Date(unix_timestamp * 1000);

// Get day
const day = date.getDate()

// Get month
const month = date.getMonth()+1

//Get Year
const year = date.getFullYear()

// Hours part from the timestamp
const hours = date.getHours();
// Minutes part from the timestamp
const minutes = date.getMinutes();
// Seconds part from the timestamp
const seconds = date.getSeconds();

// Will display time in 10:30:23 format
const formattedTime = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;

return formattedTime;
}

export function returnUSStates () {
  return [
    {
      "name": "Alabama",
      "code": "US-AL"
    },
    {
      "name": "Alaska",
      "code": "US-AK"
    },
    {
      "name": "American Samoa",
      "code": "US-AS"
    },
    {
      "name": "Arizona",
      "code": "US-AZ"
    },
    {
      "name": "Arkansas",
      "code": "US-AR"
    },
    {
      "name": "California",
      "code": "US-CA"
    },
    {
      "name": "Colorado",
      "code": "US-CO"
    },
    {
      "name": "Connecticut",
      "code": "US-CT"
    },
    {
      "name": "Delaware",
      "code": "US-DE"
    },
    {
      "name": "District Of Columbia",
      "code": "US-DC"
    },
    {
      "name": "Federated States Of Micronesia",
      "code": "US-FM"
    },
    {
      "name": "Florida",
      "code": "US-FL"
    },
    {
      "name": "Georgia",
      "code": "US-GA"
    },
    {
      "name": "Guam",
      "code": "US-GU"
    },
    {
      "name": "Hawaii",
      "code": "US-HI"
    },
    {
      "name": "Idaho",
      "code": "US-ID"
    },
    {
      "name": "Illinois",
      "code": "US-IL"
    },
    {
      "name": "Indiana",
      "code": "US-IN"
    },
    {
      "name": "Iowa",
      "code": "US-IA"
    },
    {
      "name": "Kansas",
      "code": "US-KS"
    },
    {
      "name": "Kentucky",
      "code": "US-KY"
    },
    {
      "name": "Louisiana",
      "code": "US-LA"
    },
    {
      "name": "Maine",
      "code": "US-ME"
    },
    {
      "name": "Marshall Islands",
      "code": "US-MH"
    },
    {
      "name": "Maryland",
      "code": "US-MD"
    },
    {
      "name": "Massachusetts",
      "code": "US-MA"
    },
    {
      "name": "Michigan",
      "code": "US-MI"
    },
    {
      "name": "Minnesota",
      "code": "US-MN"
    },
    {
      "name": "Mississippi",
      "code": "US-MS"
    },
    {
      "name": "Missouri",
      "code": "US-MO"
    },
    {
      "name": "Montana",
      "code": "US-MT"
    },
    {
      "name": "Nebraska",
      "code": "US-NE"
    },
    {
      "name": "Nevada",
      "code": "US-NV"
    },
    {
      "name": "New Hampshire",
      "code": "US-NH"
    },
    {
      "name": "New Jersey",
      "code": "US-NJ"
    },
    {
      "name": "New Mexico",
      "code": "US-NM"
    },
    {
      "name": "New York",
      "code": "US-NY"
    },
    {
      "name": "North Carolina",
      "code": "US-NC"
    },
    {
      "name": "North Dakota",
      "code": "US-ND"
    },
    {
      "name": "Northern Mariana Islands",
      "code": "US-MP"
    },
    {
      "name": "Ohio",
      "code": "US-OH"
    },
    {
      "name": "Oklahoma",
      "code": "US-OK"
    },
    {
      "name": "Oregon",
      "code": "US-OR"
    },
    {
      "name": "Palau",
      "code": "US-PW"
    },
    {
      "name": "Pennsylvania",
      "code": "US-PA"
    },
    {
      "name": "Puerto Rico",
      "code": "US-PR"
    },
    {
      "name": "Rhode Island",
      "code": "US-RI"
    },
    {
      "name": "South Carolina",
      "code": "US-SC"
    },
    {
      "name": "South Dakota",
      "code": "US-SD"
    },
    {
      "name": "Tennessee",
      "code": "US-TN"
    },
    {
      "name": "Texas",
      "code": "US-TX"
    },
    {
      "name": "Utah",
      "code": "US-UT"
    },
    {
      "name": "Vermont",
      "code": "US-VT"
    },
    {
      "name": "Virgin Islands",
      "code": "US-VI"
    },
    {
      "name": "Virginia",
      "code": "US-VA"
    },
    {
      "name": "Washington",
      "code": "US-WA"
    },
    {
      "name": "West Virginia",
      "code": "US-WV"
    },
    {
      "name": "Wisconsin",
      "code": "US-WI"
    },
    {
      "name": "Wyoming",
      "code": "US-WY"
    }
  ]
}