import React, { Component } from "react"
import { Form, Button, InputGroup } from 'react-bootstrap'

import { connect } from 'react-redux'
import '../style/App.scss'

import { getCheckoutObject, 
        //  handleCaptureOrder, 
         handleSquareCaptureOrder,
         setTaxZone,
         checkDiscount } from '../utils/api'
import { checkoutToken } from '../actions/checkout'
import { returnUSStates, sanitizedLineItems, storageAvailable } from "../utils/helper"
import { handleRefreshCart } from "../actions/cart"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toggleError, toggleSuccess } from "../actions/feedback"
import { payments } from '@square/web-sdk'
import darkModeCardStyle from '../utils/cardStyle'
import { hideLoading, showLoading } from "react-redux-loading"


class CheckoutWithoutNavigate extends Component {

  state = {
    firstname: '',
    lastname: '',
    email: '',
    shippingName: '',
    shippingStreet: '',
    shippingCity: '',
    shippingCountry: 'US',
    shippingStateProvince: '',
    shippingPostalZipCode: '',

    billingStreet: '',
    billingCity: '',
    billingCountry: 'US',
    billingStateProvince: '',
    billingPostalZipCode: '',
    telephone: '',

    shippingOptions: [],
    deliveryOption: 'ship_BkyN5YWBAw0b69',
    deliveryCost: '0',
    // cardName: '', // use for testing with handleCaptureOrder
    // cardNum: '', // use for testing with handleCaptureOrder
    // expMonth: '', // use for testing with handleCaptureOrder
    // expYear: '', // use for testing with handleCaptureOrder
    // ccv: '', // use for testing with handleCaptureOrder
    errors: [],
    liveObj: {},
    taxAmount: 0,
    total: 0,
    discount: 0,
    billingForm: false,
    card: {},
    confirmButtonDisabled: false,
    redeemButtonDisabled: false,
    squarePayments: {}
  }

  handleInputChange = this.handleInputChange.bind(this)
  handleSubmit = this.handleSubmit.bind(this)
  handleBillingInfo = this.handleBillingInfo.bind(this)
  validateDiscountCode = this.validateDiscountCode.bind(this) 

  async componentDidMount() {
    const cartId = this.props.params.id
    const checkout = await getCheckoutObject(cartId)
    this.props.dispatch(checkoutToken(checkout))
    const liveObj = await setTaxZone(checkout.id)
    const options = checkout.shipping_methods

    

    //  Initialize the Square Payments object with your application ID and the 
    //  ID of the seller location where the payment is taken once the `Square` script is loaded.
    //  We will use this object to instantiate `Payment` methods later on
    const squarePayments = await payments(
      checkout.gateways[0].config.application_id, 
      checkout.gateways[0].config.location_id
      );
    this.setState(() => ({
      squarePayments
    }))
    
    const initializeCard = async () => {
      // Creates a new Card instance with all of the behaviors and properties needed to take a card payment.
      const card = await squarePayments.card({
        // Pass in card options styles
        style: darkModeCardStyle,
      });
      this.setState(() => ({
        card
      }))
      // Attach the Card instance to the empty DOM element defined in the above HTML code snippet. The payment card form renders into the empty element, turning it into a secure payment card form.
      await card.attach('#card-container'); 
      return card; 
    }

    initializeCard()

    if (checkout.error) {
      this.props.dispatch(toggleError("We are having difficulties baking this order. \n Redirecting to cart"))
      setTimeout(() => {
        this.props.navigate('/cart')
      }, 4000)
      return
    }
    this.setState(() => ({
      shippingOptions: options,
      liveObj,
      taxAmount: liveObj.tax.amount.raw,
      total: liveObj.live.total_with_tax.raw,
    }))
  }

  hasError(key) {
    return this.state.errors.indexOf(key) !== -1;
  }


  async handleInputChange(event) {
    const key = event.target.name
    const value = event.target.value
    if (key === 'deliveryOption') {
      const id = value.split(':')[0]
      const cost = value.split(':')[1] || '0'
      this.setState(() => ({
        [key]: id,
        deliveryCost: cost
      }))
    } else {
      this.setState(() => ({
        [key]: value
      }))
    }
  }

  handleBillingInfo(event) {
    this.setState(() => ({
      billingForm: !event.target.checked
    }))
  }

  /**
   * 
   * @param {[String]} command 'enabled' will set the button to enabled, anything else will set to disabled. 
   *                            Default enabled
   */
  handleConfirmButton(command='disabled') {
    const disabled = command.toLowerCase() === 'disabled' ? true : false
    this.setState(() => ({
      confirmButtonDisabled: disabled
    }))
  }

  /**
   * 
   * @param {[String]} command 'enabled' will set the button to enabled, anything else will set to disabled. 
   *                            Default enabled
   */
   handleRedeemButton(command='disabled') {
    const disabled = command.toLowerCase() === 'disabled' ? true : false
    this.setState(() => ({
      redeemButtonDisabled: disabled
    }))
  }

  async validateDiscountCode(e) {
    e.preventDefault()
    this.handleRedeemButton('disabled')
    const code = e.target.code.value
    const checkoutId = this.state.liveObj.id
    const response = await checkDiscount(checkoutId, code)
    if (response.error) {
      this.props.dispatch(toggleError("An error occurred while processing your request."))
      this.handleRedeemButton('enabled')
      return
    }
    if(response.valid === false) {
      this.props.dispatch(toggleError("Invalid discount code entered. Please try with another code."))
      this.handleRedeemButton('enabled')
      return
    }
    this.props.dispatch(toggleSuccess(`Discount code ${code} successfully applied!`))
    this.setState(() => ({
      liveObj: response,
      discount: response.discount
    }))
    e.target.code.value = ""
    this.handleRedeemButton('enabled')
  }

  async handleCaptureCheckout(e) {
    e.preventDefault();
    const orderData = {
      line_items: sanitizedLineItems(this.props.cart.cart.line_items),
      customer: {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
      },
      shipping: {
        name: `${this.state.firstname} ${this.state.lastname}`,
        street: this.state.shippingStreet,
        town_city: this.state.shippingCity,
        county_state: this.state.shippingStateProvince,
        postal_zip_code: this.state.shippingPostalZipCode,
        country: this.state.shippingCountry,
      },
      fulfillment: {
        shipping_method: this.state.deliveryOption
      },
      billing: {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        name: `${this.state.firstname} ${this.state.lastname}`,
        street: this.state.billingForm ? this.state.billingStreet : this.state.shippingStreet,
        town_city: this.state.billingForm ? this.state.billingCity : this.state.shippingCity,
        county_state: this.state.billingForm ? this.state.billingStateProvince : this.state.shippingStateProvince,
        postal_zip_code: this.state.billingForm ? this.state.billingPostalZipCode : this.state.shippingPostalZipCode,
        country: this.state.billingForm ? this.state.billingCountry : this.state.shippingCountry,
      },
      extra_fields: {
        extr_ZM8X5nq6ropv4q: this.state.telephone
      },

      // USE THE BELOW ONLY FOR TESTING IN CONJUNCTION WITH handleCaptureOrder
      payment: {
        gateway: "test",
        card: {
          number: this.state.cardNum,
          expiry_month: this.state.expMonth,
          expiry_year: this.state.expYear,
          cvc: this.state.ccv,
          postal_zip_code: this.state.shippingPostalZipCode,
        }
      },
    };
    const order = await handleSquareCaptureOrder(
    // const order = await handleCaptureOrder( // Use this line for testing
      this.state.card, 
      this.props.checkout.checkout, 
      orderData, 
      this.state.squarePayments
      )

    // need to dispatch and show the error in case of error
    if (order.error) {
      this.props.dispatch(toggleError(order.error.data.error.message))
      this.handleConfirmButton('enable')
      this.props.dispatch(hideLoading())
      return
    }
    this.props.dispatch(handleRefreshCart())
    if (storageAvailable("sessionStorage")) {
      // Store the order in session storage so we can show it again if the
      // user refreshes the page!
      window.sessionStorage.setItem('order_receipt', JSON.stringify(order))
    }
    this.props.dispatch(hideLoading())
    this.props.navigate('/confirmation', { state: { order } })
  }

  handleSubmit(event) {
    event.preventDefault();
    //VALIDATE
    const errors = [];

    for (const key in this.state) {
      if (this.state[key] === "") {
        if (key !== 'shippingName') {
          errors.push(key)
        }
      }
    }

    //email
    const expression = /\S+@\S+/;
    var validEmail = expression.test(String(this.state.email).toLowerCase());

    if (!validEmail) {
      errors.push("email");
    }

    // Exclude billing form errors if the form is hidden
    const filtered = errors.filter((str) => !str.includes('billing'))
    const forState = this.state.billingForm ? errors : filtered
    this.setState(() => ({
      errors: forState
    }))

    if (forState.length > 0) {
      return false;
    } else {
      const shippingName = `${this.state.firstname} ${this.state.lastname}`
      this.setState(() => ({
        shippingName
      }))
    }

    // No form errors if we get here
    this.props.dispatch(showLoading())

    // Disable confirm order button
    this.handleConfirmButton('disable')
    this.handleCaptureCheckout(event)
  }

 

  render() {
    
    // const { cart } = this.props
    const delivery = parseFloat(this.state.deliveryCost) || 0
    const liveObj = this.state.liveObj
    return (
      Object.keys(liveObj).length === 0
        ?
        <div className="mt-5 mb-5 text-center fs-2">
          Loading ingredients...
        </div>
        :
        <div className="container mt-5">
          <div className="row">
            {/* Cart information in first div */}
            <div className="col-md-4 order-md-2 mb-0">
              <h4 className="checkout__subheading summary__row">
                <span className="text-muted">Your cart</span>
                <span className="badge bg-secondary">
                  {this.props.cart.cart.total_items}
                </span>
              </h4>
                <ul className="list-group">
                  {liveObj.live.line_items.map((item) => (
                    <li className="list-group-item d-flex justify-content-between lh-condensed"
                      key={item.id}
                    >
                      <div>
                        <h6 className="my-0">{item.name}</h6>
                        <small className="text-muted">
                          {item.quantity} x {item.price.formatted_with_symbol}
                        </small>
                      </div>
                      <span className="text-muted">{item.line_total.formatted_with_symbol}</span>
                    </li>
                  ))}
                </ul>
              <h4 className="checkout__subheading summary__row subtotal__row">
                <span >Subtotal</span>
                <span className="">
                  {liveObj.live.subtotal.formatted_with_symbol}
                </span>
              </h4>

              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed"
                  key={'shipping'}>
                  <div>
                    <h6 className="my-0">Delivery</h6>
                  </div>
                  <span className="text-muted">${delivery}.00</span>
                </li>
                {Object.keys(this.state.discount).length > 0 && (
                  <li className="list-group-item d-flex justify-content-between lh-condensed"
                    key={'discountCode'}>
                    <div>
                      <h6 className="my-0">Discount</h6>
                    </div>
                    <span className="text-muted">
                      -{this.state.liveObj.discount.amount_saved.formatted_with_symbol}
                    </span>
                  </li>
                )} 
                <li className="list-group-item d-flex justify-content-between lh-condensed"
                  key={'taxes'}>
                  <div>
                    <h6 className="my-0">Tax</h6>
                  </div>
                  <span className="text-muted">
                    {liveObj.live.tax === undefined ? "Calculating..." : liveObj.live.tax.amount.formatted_with_symbol}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between total__row"
                  key={'total'}>
                  <div>
                    <h6 className="my-0">Total</h6>
                  </div>
                  <span>{Math.round((liveObj.live.total_with_tax.raw+delivery + Number.EPSILON) * 100) / 100}</span>
                </li>
              </ul>
              <Form className="card p-2" onSubmit={this.validateDiscountCode}>
                <InputGroup>
                  <input type="text" className="form-control" placeholder="Promo code" 
                    name="code"/>
                  <div className="input-group-append">
                    <Button type="submit" className="btn btn-secondary"
                    disabled={this.state.redeemButtonDisabled}
                    >
                      Redeem
                    </Button>
                  </div>
                </InputGroup>
              </Form>
            </div>

            {/* Insert customer information in second div */}
            <div className="col-md-8 order-md-1">
              <Form className="needs-validation" noValidate>
                <h4 className="checkout__subheading">Customer information</h4>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" id="firstname">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstname"
                        placeholder="Enter your name"
                        required
                        value={this.state.firstname}
                        onChange={this.handleInputChange}
                        className={
                          this.hasError("firstname")
                            ? "is-invalid" : ""
                        } />
                      <Form.Text className="invalid-feedback">
                        First name is required.
                      </Form.Text>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" id="lastname">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your last name"
                        required
                        name="lastname"
                        value={this.state.lastname}
                        onChange={this.handleInputChange}
                        className={
                          this.hasError("lastname")
                            ? "is-invalid" : ""
                        } />
                      <Form.Text className="invalid-feedback">
                        Last name is required.
                      </Form.Text>
                    </Form.Group>
                  </div>
                </div>

                <Form.Group className="mb-3" id="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email"
                    placeholder="Enter email"
                    required
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    className={
                      this.hasError("email")
                        ? "is-invalid" : ""
                    } />
                  <Form.Text className="invalid-feedback">
                    Email is invalid or missing.
                  </Form.Text>
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <h4 className="checkout__subheading">Delivery details</h4>

                <Form.Group className="mb-3" id="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text"
                    placeholder="1234 Main St., apt 123"
                    required
                    name="shippingStreet"
                    value={this.state.shippingStreet}
                    onChange={this.handleInputChange}
                    className={
                      this.hasError("shippingStreet")
                        ? "is-invalid" : ""
                    } />
                  <Form.Text className="invalid-feedback">
                    Street name is required.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" id="city">
                  <Form.Label>
                    City
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="shippingCity"
                    value={this.state.shippingCity}
                    onChange={this.handleInputChange}
                    className={
                      this.hasError("shippingCity")
                        ? "is-invalid" : ""}
                  />
                  <Form.Text className="invalid-feedback">
                    City is required.
                  </Form.Text>
                </Form.Group>

                <div className="row mb-3">
                  <Form.Group className="col-md-5">
                    <Form.Label>
                      Country
                    </Form.Label>
                    <Form.Select
                      name="shippingCountry"
                      required
                      value={this.state.shippingCountry}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("shippingCountry")
                          ? "is-invalid custom-select d-block w-100" : "custom-select d-block w-100"
                      }>
                      <option value="">Select</option>
                      <option value="US">United States</option>
                    </Form.Select>
                    <Form.Text className="invalid-feedback">
                      Please select a valid country.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="col-md-4">
                    <Form.Label>
                      State
                    </Form.Label>
                    <Form.Select
                      name="shippingStateProvince"
                      required
                      value={this.state.shippingStateProvince}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("shippingStateProvince")
                          ? "is-invalid custom-select d-block w-100" : "custom-select d-block w-100"
                      }>
                      <option value="">Select</option>
                      <option value="US-FL">Florida</option>
                    </Form.Select>
                    <Form.Text className="invalid-feedback">
                      Please select a valid state.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="col-md-3" id="zip">
                    <Form.Label>
                      ZIP
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="shippingPostalZipCode"
                      value={this.state.shippingPostalZipCode}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("shippingPostalZipCode")
                          ? "is-invalid" : ""
                      } />
                    <Form.Text className="invalid-feedback">
                      ZIP code is required.
                    </Form.Text>
                  </Form.Group>
                </div>

                <Form.Group className="col-md-6 col-12" id="telephone">
                    <Form.Label>
                      Phone number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="telephone"
                      value={this.state.telephone}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("telephone")
                          ? "is-invalid" : ""
                      } />
                    <Form.Text className="invalid-feedback">
                      Phone number is required.
                    </Form.Text>
                  </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Delivery Option
                  </Form.Label>
                  <div>
                    <Form.Text>
                      For in store pickup at zero cost, select "Pick up in store" or leave it blank.
                    </Form.Text>
                  </div>
                  <div className="input-group">
                    <Form.Select
                      name="deliveryOption"
                      required
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("deliveryOption")
                          ? "is-invalid custom-select form-control" : "custom-select form-control"
                      }>
                      <option value="ship_BkyN5YWBAw0b69:0">Select</option>
                      {this.state.shippingOptions &&
                        this.state.shippingOptions.map((option) => (
                          <option key={option.id} value={`${option.id}:${option.price.raw}`} >
                            {option.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="input-group-append">
                      <span className="input-group-text">{`$${this.state.deliveryCost}.00`}</span>
                    </div>
                  </div>
                  <Form.Text className="text-muted">
                      Delivery charges will be amended after the checkout if incorrect delivery zone is selected.
                    </Form.Text>
                  <Form.Text className="invalid-feedback">
                    Please select the delivery area.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" id="deliveryAddress">
                  <Form.Check type="checkbox"
                    label="Billing address is the same as delivery address"
                    defaultChecked
                    onChange={this.handleBillingInfo}
                  />
                </Form.Group>
                <hr className="mb-2" />
                
                {/* Create delivery form in case checkbox is unchecked*/}
                {this.state.billingForm &&
                  (
                    <div className="mb-3">
                      <h4 className="checkout__subheading">Billing Details</h4>
                      <Form.Group className="mb-3" id="billingaddress">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text"
                          placeholder="1234 Main St."
                          required
                          name="billingStreet"
                          value={this.state.billingStreet}
                          onChange={this.handleInputChange}
                          className={
                            this.hasError("billingStreet")
                              ? "is-invalid" : ""
                          } />
                        <Form.Text className="invalid-feedback">
                          Billing street name is required.
                        </Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          City
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Miami"
                          name="billingCity"
                          value={this.state.billingCity}
                          onChange={this.handleInputChange}
                          className={
                            this.hasError("billingCity")
                              ? "is-invalid" : ""}
                        />
                        <Form.Text className="invalid-feedback">
                          City is required.
                        </Form.Text>
                      </Form.Group>

                      <div className="row">
                        <Form.Group className="col-md-5">
                          <Form.Label>
                            Country
                          </Form.Label>
                          <Form.Select
                            name="billingCountry"
                            required
                            value={this.state.shippingCountry}
                            onChange={this.handleInputChange}
                            className={
                              this.hasError("billingCountry")
                                ? "is-invalid custom-select d-block w-100" : "custom-select d-block w-100"
                            }>
                            <option value="">Select</option>
                            <option value="US">United States</option>
                          </Form.Select>
                          <Form.Text className="invalid-feedback">
                            Please select a valid country.
                          </Form.Text>
                        </Form.Group>

                        <Form.Group className="col-md-4">
                          <Form.Label>
                            State
                          </Form.Label>
                          <Form.Select
                            name="billingStateProvince"
                            required
                            value={this.state.billingStateProvince}
                            onChange={this.handleInputChange}
                            className={
                              this.hasError("billingStateProvince")
                                ? "is-invalid custom-select d-block w-100" : "custom-select d-block w-100"
                            }>
                            <option value="">Select</option>
                            {returnUSStates().map((option) => (
                              <option value={option.code} key={option.code}>{option.name}</option>
                            ))}
                          </Form.Select>
                          <Form.Text className="invalid-feedback">
                            Please select a valid billing state.
                          </Form.Text>
                        </Form.Group>

                        <Form.Group className="col-md-3">
                          <Form.Label>
                            ZIP
                          </Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="billingPostalZipCode"
                            value={this.state.billingPostalZipCode}
                            onChange={this.handleInputChange}
                            className={
                              this.hasError("billingPostalZipCode")
                                ? "is-invalid" : ""
                            } />
                          <Form.Text className="invalid-feedback">
                            ZIP code is required.
                          </Form.Text>
                        </Form.Group>
                      </div>
                      <hr className="mb-2 mt-4" />
                    </div>
                  )}               

                <h4 className="checkout__subheading">Payment information</h4>

                {/* ADD PAYPAL OPTION */}
                {/* <Paypal options={{ "client-id": "test" }}/> */}
                
                {/* SQUARE PAYMENT */}
                <div id="payment-form">
                  <div id="card-container"></div>
                </div>

                {/* USE THE BELOW FORM FOR TESTING WITH handleCaptureOrder */}
                {/* <div className="row">
                  <Form.Group className="col-md-6" id="cardName">
                    <Form.Label>
                      Name on the card
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="cardName"
                      value={this.state.cardName}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("cardName")
                          ? "is-invalid" : ""
                      } />
                    <small className="text-muted">Full name as written on card.</small>
                    <Form.Text className="invalid-feedback">
                      Name on card is required.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="col-md-6" id="cardNum">
                    <Form.Label>
                      Credit/Debit card number
                    </Form.Label>
                    <Form.Control
                      type="number"
                      required
                      name="cardNum"
                      value={this.state.cardNum}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("cardNum")
                          ? "is-invalid" : ""
                      } />
                    <Form.Text className="invalid-feedback">
                      Card number is required.
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-3" id="expMonth">
                    <Form.Label>
                      Exp. Month
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      pattern="\[0-9]+"
                      maxLength="2"
                      placeholder="MM"
                      name="expMonth"
                      value={this.state.expMonth}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("expMonth")
                          ? "is-invalid" : ""
                      } />
                    <Form.Text className="invalid-feedback">
                      Expiration month in MM format is required.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="col-md-3" id="expYear">
                    <Form.Label>
                      Exp. Year
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      pattern="\[0-9]+"
                      maxLength="4"
                      name="expYear"
                      placeholder="YYYY"
                      value={this.state.expYear}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("expYear")
                          ? "is-invalid" : ""
                      } />
                    <Form.Text className="invalid-feedback">
                      Expiration year in YYYY format is required.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="col-md-3" id="ccv">
                    <Form.Label>
                      CCV
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      pattern="\[0-9]+"
                      maxLength="4"
                      name="ccv"
                      value={this.state.ccv}
                      onChange={this.handleInputChange}
                      className={
                        this.hasError("ccv")
                          ? "is-invalid" : ""
                      } />
                    <Form.Text className="invalid-feedback">
                      3 or 4 digits security code required.
                    </Form.Text>
                  </Form.Group>
                </div> */}
                  {/* !!!END OF CARD TESTING FORM!!! */}


                <hr className="mb-4" />
                <div className="mb-2">
                  By confirming this order you agree to the website 
                  <Link 
                    to="/terms" 
                    target="_blank"
                    className="link"
                    >
                    &nbsp;Terms
                  </Link> 
                  &nbsp;and 
                  <Link 
                    to="/privacy" 
                    target="_blank"
                    className="link"
                    >
                      &nbsp;Privacy Policy
                    </Link>
                </div>
                <Button className="btn btn-primary btn-lg btn-block mb-3" type="submit"
                  disabled={this.state.confirmButtonDisabled}
                  onClick={this.handleSubmit}>Confirm order</Button>
              </Form>

            </div>
          </div>

        </div>
    )
  }
}

function mapStateToProps({ cart, checkout }) {
  return {
    cart,
    checkout
  }
}

function Checkout(props) {
  let navigate = useNavigate()
  const params = useParams()
  return <CheckoutWithoutNavigate {...props}
    navigate={navigate}
    params={params}
  />
}

export default connect(mapStateToProps)(Checkout)