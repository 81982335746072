import { RECEIVE_ITEMS, 
         RECEIVE_CATEGORIES,
       } from "../actions/data"

export default function data (state = { }, action ) {
  switch (action.type ) {
    case RECEIVE_ITEMS:
      return {
        ...state,
        items: {...action.items}
      }
    case RECEIVE_CATEGORIES:
      return {
        ...state,
        categories: {...action.categories}
      }

    default:
      return state
  }
}