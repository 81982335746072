import React from 'react'
import '../style/App.scss'

import Confetti from './Confetti'
import { motion } from "framer-motion";

function FadeInWhenVisible({ children, delay=0.2 }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={
        {
         duration: 0.4, 
         delay, 
         bounce: 0.4, 
         type: 'spring'}
        }
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}
function Events() {

    return (
      <div className='background-events pt-2'>
        < Confetti />
        <div className='container'>
        <FadeInWhenVisible delay={0.5}>
          <div className='events-header'>
            BRING A SLICE OF ITALY TO YOUR NEXT EVENT
          </div>
        </FadeInWhenVisible>

        <h2 className='mt-5 events-header2'>
          Impress your guests with an extraordinary Italian culinary experience.
        </h2>
        <h2 className='mt-5 events-header2'>
          Catering for:
        </h2>

        <ul>


        <FadeInWhenVisible>
          <li className='events-list-item'>Corporate Parties</li>
        </FadeInWhenVisible>
        <FadeInWhenVisible>
          <li className='events-list-item'>Birthdays</li>
        </FadeInWhenVisible>
        <FadeInWhenVisible>
          <li className='events-list-item'>Sport Events</li>
        </FadeInWhenVisible>
         <FadeInWhenVisible>
          <li className='events-list-item'>Lunch/Dinner</li>
         </FadeInWhenVisible>
        <FadeInWhenVisible>
          <li className='events-list-item'>...and more</li>
        </FadeInWhenVisible>
        </ul>
        <section className='fs-3 pt-3 pb-5'>
        
          For pricing and information contact us at: <a href = "mailto: info@pizzacini.com">info@pizzacini.com</a>
        </section>
        </div>
      </div>
    )
  }

export default Events