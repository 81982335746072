import img1 from '../style/images/slideshow/img1.jpg'
import img2 from '../style/images/slideshow/img2.jpg'
import img3 from '../style/images/slideshow/img3.jpg'
import img4 from '../style/images/slideshow/img4.jpg'
import img5 from '../style/images/slideshow/img5.jpg'
import img6 from '../style/images/slideshow/img6.jpg'
import img7 from '../style/images/slideshow/img7.jpg'
import img8 from '../style/images/slideshow/img8.jpg'
import img9 from '../style/images/slideshow/img9.jpg'
import img10 from '../style/images/slideshow/img10.jpg'
import img11 from '../style/images/slideshow/img11.jpg'
import img12 from '../style/images/slideshow/img12.jpg'
import img13 from '../style/images/slideshow/img13.jpg'
import img14 from '../style/images/slideshow/img14.jpg'

export default [img1, img2, img3, img4, img5, img6, img7, img8, img9,
                img10, img11, img12, img13, img14]