import React from 'react'
import { connect } from 'react-redux'

function Feedback (props) {

  const classes = props.blink.show ? "confirmation blink" : "confirmation"
  return (
    <div className={classes}>
      <div className="d-flex">
        <div className="flag-green"></div>
        <div className="flag-white"></div>
        <div className="flag-red"></div>
      </div>
    </div>
  )
}

const mapStateToProps = ({feedback}) => {
  return {
    blink: feedback
  }
}

export default connect(mapStateToProps)(Feedback)