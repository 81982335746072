import React from 'react'
import '../style/App.scss'
import { Image } from 'react-bootstrap'
import logo_font from '../style/images/logo_font.png'


function Privacy() {
  return (
    <div className='mb-5 text-justify'>
      <h1 className='title text-center mb-5'>Privacy Policy</h1>
      <div className='container mt-5 '>
        <div className='logo-header'>
          <Image src={logo_font} fluid="true" className='mt-3' alt='logo font' />
        </div>
        <p>
          Protecting your private information is our priority. This Statement of Privacy applies to 2737 NW 21st Street, and PIZZACINI Corp. and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to PIZZACINI Corp. include 2737 NW 21st Street and PIZZACINI. The PIZZACINI website is a ecommerce site. By using the PIZZACINI website, you consent to the data practices described in this statement.
        </p>

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Collection of your Personal Information</h3>

        In order to better provide you with products and services offered, PIZZACINI may collect personally identifiable information, such as your:
        <ul className='list-group list-group-flush mb-2'>
          <li className='list-group-item'>First and Last Name</li>
          <li className='list-group-item'>Mailing Address</li>
          <li className='list-group-item'>E-mail Address</li>
          <li className='list-group-item'>Phone Number</li>
        </ul>

        If you purchase PIZZACINI's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.
        We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Use of your Personal Information</h3>
        PIZZACINI collects and uses your personal information to operate and deliver the services you have requested.
        PIZZACINI may also use your personally identifiable information to inform you of other products or services available from PIZZACINI and its affiliates.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Sharing Information with Third Parties</h3>
        PIZZACINI does not sell, rent or lease its customer lists to third parties.
        PIZZACINI may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. PIZZACINI may share data with trusted partners to help perform statistical analysis, send you
        mail or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to PIZZACINI, and they are required to maintain the confidentiality of your information.
        PIZZACINI may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on PIZZACINI or the site; (b) protect and defend the rights or property of PIZZACINI; and/or (c) act under exigent circumstances to protect the personal safety of users of PIZZACINI, or the public.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Automatically Collected Information</h3>
        Information about your computer hardware and software may be automatically collected by PIZZACINI. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the PIZZACINI website.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Use of Cookies</h3>
        The PIZZACINI website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
        One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize PIZZACINI pages, or register with PIZZACINI site or services, a cookie helps PIZZACINI to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same PIZZACINI website, the information you previously provided can be retrieved, so you can easily use the PIZZACINI features that you customized.
        You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the PIZZACINI services or websites you visit.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Links</h3>
        This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Security of your Personal Information</h3>
        PIZZACINI secures your personal information from unauthorized access, use, or disclosure. PIZZACINI uses the following methods for this purpose:
        <ul>
          <li>- SSL Protocol</li>
          <li>- Square</li>
        </ul>

        When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
        We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Right to Deletion</h3>
        Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
        <ul className='ms-4 mb-3'>
          <li className='mb-2'>
            • Delete your personal information from our records; and
          </li>
          <li className='mb-2'>
            • Direct any service providers to delete your personal information from their records.
          </li>
        </ul>

        Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
        <ul className='ms-4 mb-3'>
          <li className='mb-2'>
            • Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;
          </li>
          <li className='mb-2'>
            • Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;
          </li>
          <li className='mb-2'>
            • Debug to identify and repair errors that impair existing intended functionality;
          </li>
          <li className='mb-2'>
            • Exercise free speech, ensure the right of another consumer to exercise his or her right
            of free speech, or exercise another right provided for by law;
          </li>
          <li className='mb-2'>
            • Comply with the California Electronic Communications Privacy Act;
          </li>
          <li className='mb-2'>
            • Engage in public or peer-reviewed scientific, historical, or statistical research in the
            public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;
          </li>
          <li className='mb-2'>
            • Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;
          </li>
          <li className='mb-2'>
            • Comply with an existing legal obligation; or
          </li>
          <li>
            • Otherwise use your personal information, internally, in a lawful manner that is
            compatible with the context in which you provided the information.
          </li>
        </ul>



        <h3 className='paragraph-title fs-3 m-0 pt-2'>Children Under Thirteen</h3>

        PIZZACINI does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>E-mail Communications</h3>
        From time to time, PIZZACINI may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.

        <h3 className='paragraph-title fs-3 m-0 pt-2'>Changes to this Statement</h3>
        PIZZACINI reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.


        <h3 className='paragraph-title fs-3 m-0 pt-2'>Contact Us</h3>

        PIZZACINI welcomes your questions or comments regarding this Statement of Privacy. If you believe that PIZZACINI has not adhered to this Statement, please contact PIZZACINI at:
        <br />
        <p className='mt-2 mb-2'>
          PIZZACINI Corp.<br />
          2737 NW 21st Street <br />
          Miami, Florida 33142<br />
        </p>
        <p className='mb-5'>
          Email Address: info@pizzacini.com<br />
        </p>
        Effective as of October 10, 2022
      </div>
    </div>
  )
}

export default Privacy