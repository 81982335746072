import { TOGGLE_MODAL, UPDATE_MODAL } from "../actions/modal"


export default function modal (state={}, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        modalShow: action.show,
      }
    case UPDATE_MODAL:
      return {
        ...state,
        itemId: action.itemId
      }
    default: 
      return {
        ...state,
        modalShow: false
      }
  }
}