import { SHOW_FEEDBACK, 
    HIDE_FEEDBACK, 
    SHOW_ERROR, 
    HIDE_ERROR } from "../actions/feedback"

export default function feedback (state={}, action) {
  switch (action.type) {
    case SHOW_FEEDBACK:
      return {
        ...state,
        show: action.show
      }
    case HIDE_FEEDBACK:
      return {
        ...state,
        show: action.show
      }
    case SHOW_ERROR:
      return {
        ...state,
        message: action.message,
        error: action.error
      }
    case HIDE_ERROR: 
      return {
        ...state,
        message: action.message,
        error: action.error
      }
    default: 
      return {
        ...state,
        show: false,
        error: false,
        message: undefined
      }
  }
}
