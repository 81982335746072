export const RECEIVE_ITEMS = 'RECEIVE_ITEMS'
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES'
export const DATA_ERROR = 'DATA_ERROR'

export const receiveItems = items => ({
  type: RECEIVE_ITEMS,
  items
})

export const receiveCategories = categories => ({
  type: RECEIVE_CATEGORIES,
  categories
})