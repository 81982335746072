export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const UPDATE_MODAL = 'UPDATE_MODAL'

export const toggleModal = show => ({
  type: TOGGLE_MODAL,
  show,
})


export const updateModal = (itemId) => ({
  type: UPDATE_MODAL,
  itemId
})
