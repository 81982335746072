import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { convertUnixToDatetime } from "../utils/helper"


export default function Confirmation(props) {

  const { state } = useLocation()
  const order = checkOrder()

  function checkOrder() {
    const orderInStorage = sessionStorage.getItem('order')
    if (state.order) {
      return state.order
    } else if (orderInStorage) {
      return orderInStorage
    } else {
      return null
    }
  }

  return (
    <div className="container pt-5 pb-5">
      <div className="confirmation__wrapper">
        <div className="confirmation__wrapper-header">
          <h4 className="confirmation__wrapper-title">
            Thank you for your purchase, {order.customer.firstname} {order.customer.lastname}!
          </h4>
          <p>The oven is ready, we will start baking shortly.</p>
          <p>An email confirmation has been sent to <span className="fw-bold">{order.customer.email}</span></p>
        </div>
        <div className="row p-2">
          <div className="col-6 fw-bold">
            <p>Ref: {order.customer_reference}</p>
          </div>
          <div className="col-6 text-end fw-bold">
            <p>{convertUnixToDatetime(order.created)}</p>
          </div>
        </div>
        <hr className="confirmation__wrapper-separation" />
        <div className="container">
          <div className="row ps-3 pe-3">
            <div className="col-6 fs-1">
              <div>Total</div>
            </div>
            <div className="col-6 fs-1 text-end pe-5">
              <div>{order.order.total_with_tax.formatted_with_symbol}</div>
            </div>
          </div>
          <hr />
          <div className="confirmation_subheading">
            Order Summary
          </div>
          <div>
            {order.order.line_items.map((item) => (
              <div key={item.id} className="row mt-2 pb-2">
                <div className="col-3 col-sm-2">
                  <img className="w-100" alt={item.product_name} src={item.image.url} />
                </div>
                <div className="d-flex flex-column col-6 col-sm-7">
                  <div>{item.product_name}</div>
                  <div className="fst-italic">{item.quantity}x</div>
                </div>
                <div className="text-end col-3">
                  {item.line_total.formatted_with_symbol}
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="row">
            <div className="text-end col-8">
              Subtotal
            </div>
            <div className="text-end col-4">
              {order.order.subtotal.formatted_with_symbol}
            </div>
          </div>
          {order.order.discount.amount_saved && (
            <div className="row">
              <div className="text-end col-8">
                Discount
              </div>
              <div className="text-end col-4">
                -{order.order.discount.amount_saved.formatted_with_symbol}
              </div>
            </div>
          )}
          <div className="row">
            <div className="text-end col-8">
              Taxes
            </div>
            <div className="text-end col-4">
              {order.tax.amount.formatted_with_symbol}
            </div>
          </div>
          <div className="row">
            <div className="text-end col-8">
              Delivery
            </div>
            <div className="text-end col-4">
              {order.order.shipping.price.formatted_with_symbol}
            </div>
          </div>
          <div className="row mt-4 mb-3">
              <div className="col-sm-6">
                <h4 className="fw-bold">Delivery address</h4>
                <div className="d-flex flex-column">
                  <div>{order.shipping.street}</div>
                  <div>{order.shipping.town_city}, {order.shipping.county_state} {order.shipping.postal_zip_code}</div>
                </div>
              </div>
              <div className="col-sm-6">
                <h4 className="fw-bold">Transaction details</h4>
                <div className="d-flex flex-column">
                  <div>{order.transactions[0].gateway_name}</div>
                  <div>Account ending in: {order.transactions[0].gateway_reference}</div>
                </div>
              </div>
          </div>
        </div>
        <div className="text-end">
          <Link
            className="confirmation__wrapper-back p-1"
            type="button"
            to="/home"
          >
            <Button className="bg-custom-primary btn btn-outline-light">Back to home</Button>
          </Link>
          <Link
            className="confirmation__wrapper-back p-1"
            type="button"
            to="/shop"
          >
            <Button className="bg-custom-primary btn btn-outline-light">Continue shopping</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}