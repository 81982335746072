import { 
        INITIALIZE_CART,
        ADD_CART_ITEM,
        REMOVE_ITEM,
        UPDATE_ITEM_QUANTITY,
        EMPTY_CART,
        REFRESH_CART
        } from "../actions/cart"


export default function cart (state = {}, action ) {
  switch (action.type ) {
    case INITIALIZE_CART:
        return {
            ...state,
            cart: { ...action.cart }
        }
    case ADD_CART_ITEM:
        return {
            ...state,
            cart: { ...action.cart }
        }  
    case REMOVE_ITEM:
      return {
        ...state,
        cart: { ...action.cart }
      }
    case UPDATE_ITEM_QUANTITY:
      return {
        ...state,
        cart: { ...action.cart }
      }
    case EMPTY_CART:
      return {
        ...state,
        cart: { ...action.cart }
      }
    
      case REFRESH_CART:
        return {
          ...state,
          cart: { ...action.cart }
        }

    default:
      return state
  }
}