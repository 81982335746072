import React from 'react'
import '../style/App.scss'
import { Facebook, Instagram, Youtube } from 'react-bootstrap-icons'
import { Link } from "react-router-dom"

import slogan from '../style/images/Eat_Original_White.png'
import tricolore from '../style/images/tricolore_spacer.png'


export default function Footer(props) {

  return (
    <div className="mt-3">
      <footer className="bg-custom text-center text-white footer">
        <div className="container p-4">

        <section className='text-center'>
          <div>
            <img className="w-50" src={slogan} alt="Eat Original" />
          </div>
          <img className="w-25" src={tricolore} alt="tricolore" />
        </section>
          {/* Social media */}
          <section className="mb-4 mt-5">
            <div className="mb-2">
              <a className="text-white" href="https://goo.gl/maps/Ebva48tPDQESxLky6">
                  2737 NW 21st Street, 
                  Miami, FL 33178
              </a>
            </div>
            {/* Facebook */}
            <a className="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/pizzacini" role="button">
              <Facebook size={24} />
            </a>

            {/* Instagram */}
            <a className="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/pizzaciniofficial" role="button">
              <Instagram size={24} />
            </a>

            {/* Youtube */}
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
              <Youtube size={24} />
            </a>
          </section>

          <section className="mb-4">
            <h3 className="footer-bold">
              We would love to have you visit us soon!
            </h3>
          </section>

          <section className="">

            <div className="row">
              <div className="col-md-4 mb-4 mb-md-0">
                <h5 className="text-uppercase">Contacts</h5>
                <div className="text-start row">
                  <div className='col-5 text-end'>
                    <p>Piervalerio:</p>
                    <p>Luca:</p>
                  </div>
                  <div className='col-7'>
                    <p>+1 786 292 3727</p>
                    <p>+1 786 351 5047</p>
                  </div>
                </div>  

              </div>


              <div className="col-md-4 mb-4 mb-md-0">
                <h5 className="text-uppercase">Emails</h5>
                <div>
                  <a className='f-white' href="mailto: info@pizzacini.com">info@pizzacini.com</a>
                  <div>

                  <a className='f-white' href="mailto: orders@pizzacini.com">orders@pizzacini.com</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4 mb-md-0">
                <h5 className="text-uppercase">Info</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <Link to="/terms" target="_blank" className="text-white">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy" target="_blank" className="text-white">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
          © {new Date().getFullYear()} Copyright:
          <a className="text-white" href="https://pizzacini.com/"> pizzacini.com</a>
        </div>
        <span id="siteseal">
          <script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=cLVosYPDB49Vsz2APufuj3qJ3Xm5PjmX6j5sKm2Qw9Lyv4KgqRA0TlFZtSxp">
          </script>
        </span>
      </footer>


    </div>
  )
}